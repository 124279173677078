@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oxygen&display=swap");

$app_dark: rgba(0, 0, 0, 0.9);
$app_light: rgba(245, 245, 245, 1);
$font-family: "Titillium Web", sans-serif;
$text-font: "Oxygen", sans-serif;
$text-large: 35px;
$text-semi-large: 27px;
$text-medium: 20px;
$text-semi-small: 15px;
$text-small: 13px;
$success-button: #58b32e;
$shadow-color: rgba(0, 0, 0, 0.3);
$shadow-white-color: rgba(245, 245, 245, 0.3);
$baner-color: #00ace6;
$gold: #FFBA08;
