@import "../global.scss";

* {
    margin: 0px;
    padding: 0px;
    font-family: $text-font;
    font-weight: 300;
    scroll-behavior: smooth;
    // &::-webkit-scrollbar {
    //     display: none;
    // }
}

.app {
    max-width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;

    .navbar {
        height: 45px;
        // background-color: $baner-color;
        background-color: rgb(26, 26, 26);
        color: $app_light;
        position: sticky;
        top:0px;
        z-index: 9999;
        display: flex;
        > div:nth-child(1) {
            flex: 1;
            width: 90%;
            margin-left: 2%;
            margin-right: 2%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            >:nth-child(1){
                display: flex;
                align-items: center;
                background-color: transparent;
                //icon
                .logo {
                    width: 22px;
                    height: 22px;
                }
                // title
                .title {
                    font-size: 24px;
                    margin-left: 10px;
                    font-family: $font-family;
                }
                
                > p {
                    font-size: 12px;
                    margin-left: 8px;
                    cursor: pointer;
                    margin-right: 8px;
                }
            }
            >:nth-child(2){
                margin-left: auto;
            }
            > div {
                display: flex;
                align-items: center;
                > :nth-child(1) {
                    font-size: 17px;
                    transition: all 0.3s ease-in-out;
                }
                > a {
                    font-size: $text-semi-small;
                    margin-left: 8px;
                    color: $app-light;
                    text-decoration: none;
                    transition: all 0.3s ease-in-out;
                    color: white;
                    font-weight: 450;
                }

                &:hover {
                    color: white;
                }
                
            }
            .location_div{
                margin-left: 40px;
                >:nth-child(2){
                    font-size: 13px;
                }
            }
            .login{
                margin-left: 20px;
                cursor: pointer;
                padding-top: 4px;
                font-size: 20px;
            }
        }
    }

    .zomobotron {
        background-color: $app_dark;
        position: relative;
        color: $app_light;
        max-height: 295px;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
        overflow: hidden;
        background-color: white;
        > img:nth-child(1) {
            width: 90%;
            height: 90%;
            // margin-top: 20px;
            opacity: 0;
        }
        > div:nth-child(2) {
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 130px;

            // baner
            >div:nth-child(1){
                display: flex;
                flex-direction: row;
                // height: 50vh;
                width: 85vw;

                // defination model
                >div:nth-child(1){
                    display: flex;
                    // background-color: rgb(14, 14, 14);
                    background-color: white;
                    z-index: 999;
                    display: flex;
                    // defination image
                    > :nth-child(1) {
                        max-width: 340px;
                        max-height: 310px;
                        position: relative;
                        top: -20px;
                    }
                }

                // sliding baner
                >div:nth-child(2){
                    display: flex;
                    flex-direction: row;
                    overflow-x: hidden;
                    // background-color: rgba(0,0,0,0.4);
                    background-color: white;
                    position: relative;
                    // top:-38px;
                    // height: 98%;
                    > div {
                        display: flex;
                        flex-direction: row;
                        min-width: 100%;

                        > div:nth-child(1) {
                            transition: all 1s ease-in-out;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            flex: 1.5;
                            margin-right: 90px;
                            padding-left: 50px;
                            > h2 {
                                font-size: 22px;
                                text-align: center;
                                font-family: $font-family;
                                color: black;
                                font-weight: bold;
                            }
                            > p {
                                font-size: 14.5px;
                                margin-top: 12px;
                                opacity: 0.9;
                                text-align: center;
                                color: black;
                                line-height: 24px;
                                font-weight: bold;
                            }
                            // left, right controlls
                            >div{
                                display: flex;
                                gap: 20px;
                                >:nth-child(n){
                                    font-size: 30px;
                                    margin-top: 10px;
                                    // opacity: 0.4;
                                    color: black;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .bottom {
        display: flex;
        flex-direction: column;

        > div:nth-child(1) {
            width: 100vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            background-color: rgba(0,0,0,0.1);
            // static techgrity text
            // >div:nth-child(1){
            //     display: flex;
            //     flex-direction: column;
            //     align-items: center;
            //     justify-content: center;
            //     position: relative;
            //     padding: 20px 0px;
            //     width: 100vw;
            //     background-color: rgba(0,0,0,0.08);
                

            //     > p {
            //         font-size: 13px;
            //         margin-top: 12px;
            //         opacity: 0.9;
            //         text-align: center;
            //         width: 60%;
            //     }

            // }
            > h2 {
                padding: 6px 0px;
                font-size: 22px;
                text-align: center;
                font-family: $font-family;
                margin-bottom: 10px;
                background-color: $baner-color;
                width: 100%;
                color: white;
            }
            > p {
                padding-left: 3vw;
                padding-right: 3vw;
                font-size: 14.5px;
                text-align: center;
                // opacity: 0.9;
                line-height: 25px;
                margin-top: 6px;
            }

            //contact us button
            > div:nth-child(5) {
                background-color: $success-button;
                padding: 8px 20px;
                font-size: 12px;
                cursor: pointer;
                box-shadow: 0px 0px 3px grey;
                margin-left: 20px;
                margin-top: 20px;
                color: white;
                margin-bottom: 20px;
                &:hover {
                    box-shadow: none;
                    opacity: 0.8;
                }
            }

            >:nth-child(6){
                background-color: white;
                padding-top: 20px;
                text-align: left;
            }

            > div:nth-child(7) {
                padding-left: 7vw;
                padding-right: 7vw;
                flex: 1;
                display: flex;
                flex-direction: row;
                background-color: white;
                > div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 1;
                    border-radius: 2px;
                    margin-top: 10px;
                    padding: 6px;
                    margin-right: 20px;
                    // icon
                    >:nth-child(1){
                        font-size: 50px;
                        padding-bottom: 10px;
                        padding-top: 10px;
                    }
                    > label {
                        font-size: 15px;
                        padding-top: 4px;
                        padding-bottom: 4px;
                        margin-bottom: 8px;
                        color: black;
                        opacity: 0.9;
                        font-weight: 900;
                        border-bottom: 5px solid $baner-color;
                    }
                    > p {
                        font-size: 14.5px;
                        color: black;
                        padding-left: 8px;
                        padding-right: 8px;
                        margin-top: 10px;
                        line-height: 20px;
                        text-align: justify;
                    }
                }
            }
        }

        >div:nth-child(2){
            height: 40px;
            margin-top: 20px;
            color: rgba(245,245,245,0.5);
        }
    }

    .footer {
        height: 40px;
        background-color: rgba(0,0,0,1);
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 12px;
        bottom: 0px;
        width: 100vw;
        >label{
            display: flex;
            justify-content: row;
            gap: 5px;
            >:nth-child(1){
                margin-top: 2px;
            }
        }
    }
}

section {
    width: 100vw;
    height: 100vh;
}
