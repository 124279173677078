@import "../global.scss";

.auth {
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: center;
    padding-top: 20px;
    z-index: 9999999;
    > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        width: 400px;
        height: 240px;
        background-color: white;
        box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        padding: 20px;
        gap: 20px;
        // header
        > :nth-child(1) {
            display: flex;
            align-items: center;
            font-size: 15px;
            justify-content: center;
            flex-direction: column;
            > label {
                font-weight: bolder;
            }
            > p {
                margin-top: 8px;
                font-size: 12px;
                text-align: center;
            }
        }
        // content
        > :nth-child(2) {
            flex: 1px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            > input {
                padding: 4px;
                border: 0.1px solid rgba(0, 0, 0, 0.2);
                font-size: 11px;
                border-radius: 2px;
                height: 20px;
                width: 80%;
            }
        }
        // footer
        > :nth-child(3) {
            height: 30px;
            display: flex;
            font-size: 13px;
            cursor: pointer;
            justify-content: center;
            margin-top: 10px;
            width: 82.5%;
            margin-left: auto;
            margin-right: auto;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
            border-radius: 2px;
            > label:nth-child(1) {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                background-color: rgba(0, 0, 0, 0.5);
                flex: 1;
                cursor: pointer;
            }
            > label:nth-child(2) {
                display: flex;
                justify-content: center;
                align-items: center;
                color: white;
                background-color: $baner-color;
                flex: 1;
                cursor: pointer;
            }
        }
        // register link
        >:nth-last-child(1){
            text-align: center;
            font-size: small;
            >a{
                text-decoration: underline;
                color: blue;
                cursor: pointer;
            }
        }
    }
    .loading {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
    }
}
